<template>
    <v-dialog persistent max-width="400" v-model="dialog">
        <v-card>
            <v-card-title>Blink System</v-card-title>
            <v-card-text class="pb-0">
                <span class="text-subtitle-1" v-text="text"></span>
            </v-card-text>
            <v-card-actions class="pb-2">
                <v-spacer></v-spacer>
                <v-btn block text color="primary" :ripple="false" @click="dialog = false">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'AccessErrorDialog',
    data() {
        return {
            dialog: false,
            text: '',
            error_code: '',
        }
    },
    methods: {
        open: function(code) {
            this.dialog = true
            this.updateText(code)
        },
        close: function() {
            this.dialog = false
        },
        updateText: function(code) {
            const errorTexts = {'10000': 'Sorry, you have not logged in system yet. Please login the system to continue',
                                '10001': 'Sorry, your session has expired. Please login the system to continue.',
                                '10002': 'Sorry, you do not have a permission to access to the app',
                                '40000': 'Sorry, you do not have a permission to access to the app'}

            this.text = errorTexts[code]
        }
    }
}

</script>